import dayjs from "dayjs";
import { html } from "lit-html";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.default.css";

import xSvg from "~/img/svg/x.svg";

import {
	cloneUserReport,
	createOrUpdateUserReport,
	deleteUserReport,
} from "~/js/api.js";
import { base64ToSvg, cn } from "~/js/helpers.js";
import { Popper } from "~/js/utils/popper.js";

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

const STATES = {
	FULFILLED: 0,
	PENDING: 1,
};

const dialogInstances = new Set();
let scrolloptions = {};
const visibleDialogs = new Set();

export class Dialog {
	#abort = new AbortController();
	#callbacks;
	#canClearTimeouts = true;
	#closeButtonIcon = base64ToSvg(xSvg);
	#closeTimeout;
	#constructableSheets;
	#defaultClasses =
		"dark:border dark:border-slate-800 p-4 overflow-auto w-11/12 max-w-[800px] transition-all backdrop:backdrop-blur backdrop:bg-slate-900/30 dark:backdrop:bg-slate-900/80 rounded bg-white dark:bg-slate-900 shadow-xl shadow-slate-700/50";
	#events;
	#openTimeout;
	#reject;
	#resolve;
	#sourceTarget;
	#state = STATES.PENDING;
	#styleSheets = new Map();

	constructor({
		classes = "",
		close,
		closeButton = true,
		closeOnBackdrop = true,
		constructableSheets,
		content,
		id,
		open,
		styleSheets,
	} = {}) {
		const instance = [...dialogInstances].find(
			(dialog) => dialog.element.id === id,
		);
		if (instance) {
			instance.content(content);
			return;
		}

		this.#callbacks = { close, open };
		this.closeButton = closeButton;
		this.closeOnBackdrop = closeOnBackdrop;
		this.element = document.createElement("dialog");

		this.#constructableSheets = []
			.concat(constructableSheets)
			.filter((sheet) => sheet instanceof CSSStyleSheet);
		this.#findStyleSheets(
			[].concat(styleSheets).filter((sheet) => typeof sheet === "string"),
		);

		this.#events = {
			click: this.#onClick.bind(this),
			keydown: this.#onKeydown.bind(this),
			pointerdown: this.#onPointerdown.bind(this),
		};

		// Merge classes with existing classes
		this.element.className = cn(this.#defaultClasses, classes);

		if (id) {
			this.element.id = id;
		}

		this.content(content);
		dialogInstances.add(this);
	}

	get #hasContent() {
		return this.element.childNodes.length > 0;
	}

	get #isLastVisible() {
		return [...visibleDialogs].pop() === this;
	}

	#attachConstructableSheets() {
		for (const sheet of this.#constructableSheets) {
			document.adoptedStyleSheets.push(sheet);
		}
	}

	#attachStyleSheets() {
		return Promise.allSettled(
			[...this.#styleSheets].map(
				([url, sheet]) =>
					new Promise((resolve, reject) => {
						if (sheet) {
							return resolve();
						}

						const resource = document.createElement("link");
						resource.href = url;
						resource.rel = "stylesheet";
						resource.addEventListener("load", resolve);
						resource.addEventListener("error", reject);
						document.head.appendChild(resource);
						this.#styleSheets.set(url, resource);
					}),
			),
		);
	}

	#clearTimeouts() {
		if (this.#canClearTimeouts) {
			clearTimeout(this.#closeTimeout);
			clearTimeout(this.#openTimeout);
		}

		this.#canClearTimeouts = true;
	}

	#detachConstructableSheets() {
		document.adoptedStyleSheets = document.adoptedStyleSheets.filter(
			(sheet) => !this.#constructableSheets.includes(sheet),
		);
	}

	#detachStyleSheets() {
		for (const [url, sheet] of this.#styleSheets) {
			if (sheet) {
				sheet.remove();
				this.#styleSheets.set(url, null);
			}
		}
	}

	#findStyleSheets(styleSheets) {
		for (const sheet of styleSheets) {
			const url =
				typeof sheet === "string"
					? new URL(sheet, document.baseURI).href
					: sheet.href;
			if (!this.#styleSheets.has(url)) {
				this.#styleSheets.set(url, null);
				sheet.remove?.();
			}
		}
	}

	#lockScroll(force) {
		if (visibleDialogs.size > 0) {
			return;
		}

		if (force) {
			if (isIOS) {
				scrolloptions = {
					left: window.scrollX,
					top: window.scrollY,
					behavior: "instant",
				};
				document.documentElement.style.cssText = `left: -${scrolloptions.left}px; position: fixed; top: -${scrolloptions.top}px;`;
			} else {
				document.documentElement.style.cssText = `overflow: hidden; padding-right: ${
					window.innerWidth -
					document.documentElement.getBoundingClientRect().width
				}px`;
			}
		} else {
			document.documentElement.style.cssText = null;
			isIOS && document.documentElement.scrollTo(scrolloptions);
		}
	}

	#onClick(e) {
		if (this.#isLastVisible) {
			if (
				this.#sourceTarget === document.documentElement &&
				e.target === document.documentElement &&
				this.closeOnBackdrop
			) {
				this.#wasClickedInBackdrop(e) && this.close();
			} else {
				const target = e.target.closest("[data-dialog-action]");

				if (target) {
					const actions = e.target.dataset.dialogAction
						.toLowerCase()
						.split(/\s+/);

					(async () => {
						for (const action of actions) {
							await this[action]?.();
						}
					})();
				}
			}
			this.#sourceTarget = null;
		}
	}

	#onKeydown(e) {
		if (e.key === "Escape" && this.#isLastVisible) {
			e.preventDefault();
			this.close();
		}
	}

	#onPointerdown(e) {
		if (this.#isLastVisible) {
			if (e.target === document.documentElement) {
				// prevent user-select in firefox
				e.preventDefault();
			}

			this.#sourceTarget = e.target;
		}
	}

	#resolveAnimations() {
		return Promise.allSettled(
			this.element.getAnimations().map((animation) => animation.finished),
		);
	}

	async #resolveCallback(callback, value) {
		if (this.#state === STATES.PENDING) {
			await callback?.(value);
		}
		this.#state = STATES.FULFILLED;
		return Promise.resolve();
	}

	#wasClickedInBackdrop(e) {
		const { left, right, top, bottom } = this.element.getBoundingClientRect();
		return (
			left > e.clientX ||
			right < e.clientX ||
			top > e.clientY ||
			bottom < e.clientY
		);
	}

	abort() {
		this.#abort.abort();
		this.#abort = new AbortController();

		if (!this.#hasContent) {
			visibleDialogs.delete(this);
		}
		return this;
	}

	async close(delay) {
		this.#clearTimeouts();

		if (typeof delay === "number" && delay > 0) {
			await new Promise((resolve) => {
				this.#closeTimeout = setTimeout(() => resolve(), delay);
			});
		}

		if (this.element.open) {
			document.removeEventListener("click", this.#events.click);
			document.removeEventListener("keydown", this.#events.keydown);
			document.removeEventListener("pointerdown", this.#events.pointerdown);

			this.element.dataset.dialogState = "close";
			await this.#resolveAnimations();

			dialogInstances.delete(this);
			visibleDialogs.delete(this);

			this.element.close();
			this.element.remove();
			this.#lockScroll(false);
			this.#detachConstructableSheets();
			this.#detachStyleSheets();
			this.#callbacks.close?.(this);
			await this.reject();
			this.#state = STATES.PENDING;
		}

		return Promise.resolve();
	}

	async content(value) {
		if (value != null) {
			this.#clearTimeouts();
			this.abort();

			if (typeof value === "function") {
				return value(this, this.#abort.signal);
			}

			const element = document.createElement("div");

			if (value instanceof HTMLElement) {
				element.append(
					value instanceof HTMLTemplateElement
						? value.content.cloneNode(true)
						: value,
				);
			} else {
				element.innerHTML = value;
			}

			if (this.closeButton) {
				const closeButton = document.createElement("button");
				closeButton.append(this.#closeButtonIcon);
				closeButton.className =
					"absolute flexitems-center justify-center top-0 right-0 m-2 rounded-full p-1 bg-slate-900/5 dark:bg-slate-900/50 h-8 w-8";
				closeButton.addEventListener("click", () => this.close());
				element.prepend(closeButton);
			}

			this.#findStyleSheets([
				...element.querySelectorAll('link[rel="stylesheet"]'),
			]);
			await this.#attachStyleSheets();
			this.element.replaceChildren(...element.childNodes);
		}

		this.#canClearTimeouts = false;

		return Promise.resolve(this.element);
	}

	async open(delay) {
		this.#clearTimeouts();

		if (typeof delay === "number" && delay > 0) {
			await new Promise(
				// biome-ignore lint/suspicious/noAssignInExpressions: This is a valid use case
				(resolve) => (this.#openTimeout = setTimeout(() => resolve(), delay)),
			);
		}

		if (!this.element.open) {
			this.#attachConstructableSheets();
			await this.#attachStyleSheets();

			document.addEventListener("click", this.#events.click);
			document.addEventListener("keydown", this.#events.keydown);
			document.addEventListener("pointerdown", this.#events.pointerdown);

			if (!document.contains(this.element)) {
				document.body.appendChild(this.element);
			}

			this.#lockScroll(true);

			// Show spinner while loading
			const html_ = html`
        <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <div class='flex space-x-2 justify-center items-center'>
            <span class='sr-only'>${gettext("Caricamento")}...</span>
              <div class='h-3 w-3 dark:bg-white/25 bg-black/25 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
            <div class='h-3 w-3 dark:bg-white/25 bg-black/25 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
            <div class='h-3 w-3 dark:bg-white/25 bg-black/25 rounded-full animate-bounce'></div>
          </div>
        </div>
      `;
			this.element.innerHTML = html_.strings;

			// Finally, show the dialog
			this.element.showModal();
			this.element.dataset.dialogState = "open";

			dialogInstances.add(this);
			visibleDialogs.add(this);

			await this.#resolveAnimations();
			this.#callbacks.open?.(this);
		}

		return Promise.resolve();
	}

	success(callback) {
		if (typeof callback === "function") {
			this.#resolve = callback;
		}
		return this;
	}

	catch(callback) {
		if (typeof callback === "function") {
			this.#reject = callback;
		}
		return this;
	}

	reject(reason) {
		return this.#resolveCallback(this.#reject, reason);
	}

	resolve(value) {
		return this.#resolveCallback(this.#resolve, value);
	}
}

export class UserReportDialog extends Dialog {
	#form;
	#dateRangeStartInputEl;
	#dateRangeEndInputEl;
	// Instaces
	#tooltipsInstances = [];
	#multiSelectInstances = [];
	// Dayjs instance
	#yesterday = dayjs().subtract(1, "day");
	constructor({ reportId = null, ...options }) {
		super(options);
		this.reportId = reportId;
	}

	async content(value) {
		await super.content(value);
		this.#init();
	}

	#init() {
		this.#form = this.element.querySelector("form");
		if (!this.#form) return;

		// Only one time dimension can be selected
		const timeDimensionInputs = this.element.querySelectorAll(
			'input[name="time_dimension"]',
		);
		for (const input of timeDimensionInputs) {
			input.addEventListener("change", (e) => {
				for (const input of timeDimensionInputs) {
					if (input !== e.target) {
						input.checked = false;
					}
				}
			});
		}

		this.#dateRangeStartInputEl = this.element.querySelector(
			"input[name='date_range_start']",
		);
		this.#dateRangeEndInputEl = this.element.querySelector(
			"input[name='date_range_end']",
		);

		// Show date range inputs if custom is selected
		const dateRangeTypeInput = this.element.querySelector(
			"select[name='date_range_type']",
		);
		// Show/hide date range inputs on load
		if (dateRangeTypeInput.value === "custom") {
			this.#showDateRangeInputs();
		} else {
			this.#hideDateRangeInputs();
		}
		// Show/hide date range inputs on change
		dateRangeTypeInput.addEventListener("change", (e) => {
			e.target.value === "custom"
				? this.#showDateRangeInputs()
				: this.#hideDateRangeInputs();
		});

		const multiSelectEl = this.#form.querySelectorAll("select[multiple]");
		for (const el of multiSelectEl) {
			const select_ = new TomSelect(el, {
				allowEmptyOption: true,
				maxItems: null,
				maxOptions: 50,
				searchField: ["text", "groupName"],
				plugins: {
					clear_button: {
						html: (data) => {
							return `<div class="${data.className}" title="${data.title}">${base64ToSvg(xSvg).outerHTML}</div>`;
						},
						title: gettext("Rimuovi tutto"),
					},
					remove_button: {},
				},
				// Custom render
				render: {
					item: (data, escape_) => {
						if (data.favicon) {
							const faviconUrl = new URL(data.favicon, window.location.origin);
							return `
              <div class="inline-flex items-center gap-1">
                <img src="${faviconUrl.href}" class="rounded-full bg-slate-950/50" width="16" height="16"/>${escape_(data.text)}
              </div>`;
						}
						return `<div class="item">${escape_(data.text)}</div>`;
					},
					option: (data, escape_) => {
						if (data.favicon) {
							const faviconUrl = new URL(data.favicon, window.location.origin);
							return `
              <div class="flex items-center gap-1">
                <img src="${faviconUrl.href}" class="rounded-full bg-slate-950/50" width="16" height="16" loading="lazy"/>${escape_(data.text)}
              </div>`;
						}
						return `<div class="option">${escape_(data.text)}</div>`;
					},
					optgroup_header: (data, escape_) => {
						const div = document.createElement("div");
						div.className =
							"flex text-xs font-bold items-center justify-between p-2 bg-sky-800/10 dark:bg-white/5";

						const span = document.createElement("span");
						span.className = "text-black dark:text-white";
						span.textContent = escape_(data.label);

						div.append(span);
						return div;
					},
					no_results: () =>
						`<div class="no-results">${gettext("Nessun risultato trovato")}</div>`,
				},
				onClear: () => {
					select_.refreshOptions();
				},
				onInitialize: function () {
					if (Object.keys(this.optgroups).length > 0) {
						for (const key of Object.keys(this.options)) {
							this.updateOption(this.options[key].value, {
								...this.options[key],
								groupName: this.optgroups[this.options[key].optgroup].label,
							});
						}
					}
				},
				onItemAdd: function () {
					this.setTextboxValue("");
					this.refreshOptions();
				},
				onDropdownOpen: (dropdown) => {
					if (["id_website", "id_auction_type"].includes(el.id)) {
						dropdown.classList.add("dropup");
					}
				},
				onDropdownClose: (dropdown) => {
					if (["id_website", "id_auction_type"].includes(el.id)) {
						dropdown.classList.remove("dropup");
					}
				},
			});
			this.#multiSelectInstances.push(select_);
		}

		const submitButton = this.element.querySelector("button[type=submit]");
		if (submitButton) {
			const cloneMode = submitButton.dataset.cloneMode !== "False";
			submitButton.addEventListener("click", async (event) => {
				event.preventDefault();
				if (cloneMode === true) {
					const csrfToken = window._galada.csrfToken;
					const response = await cloneUserReport(this.reportId, csrfToken);
					if (response.status === "success") {
						// Redirect to the report page
						window.location.href = response.redirect;
					}
					return;
				}
				this.#clearErrors();
				const data = this.#parseForm();
				const response = await createOrUpdateUserReport(data, this.reportId);
				if (response.status === "success") {
					// Redirect to the report page
					window.location.href = response.redirect;
				}
				this.#parseErrors(response);
			});
		}

		if (this.reportId) {
			// Handle delete button
			const deleteButton = this.element.querySelector(
				"button[data-action='delete']",
			);
			if (deleteButton) {
				deleteButton.addEventListener("click", async (event) => {
					event.preventDefault();
					const data = this.#parseForm();
					const response = await deleteUserReport(
						this.reportId,
						data.csrfmiddlewaretoken,
					);
					if (response.status === "success") {
						await this.resolve({ action: "delete" });
						return;
					}
					alert(gettext("Errore durante l'eliminazione del report"));
				});
			}
		}

		// Handle input costraints
		this.#handleInputConstraints();
	}

	#clearErrors() {
		const errors = this.element.querySelectorAll("div.invalid-feedback");
		for (const error of errors) {
			error.innerHTML = "";
		}
		const inputs = this.element.querySelectorAll("input");
		for (const input of inputs) {
			input.ariaInvalid = false;
		}
	}

	#handleInputConstraints() {
		// RPM is aviailable only if website dimension is selected
		const rpmInput = this.element.querySelector("#metric-rpm");
		const websiteDimensionInput = this.element.querySelector(
			"#other-dimensions-website",
		);
		if (!!rpmInput && !!websiteDimensionInput) {
			const rmpInputTooltip = interpolate(
				gettext(
					"Seleziona la dimensione <b>%s</b> per abilitare questa metrica",
				),
				[gettext("Sito")],
			);
			rpmInput.disabled = !websiteDimensionInput.checked;
			if (rpmInput.disabled) {
				this.#activateTooltip(rpmInput.parentNode, rmpInputTooltip);
			} else {
				this.#deactivateTooltip(rpmInput.parentNode);
			}
			websiteDimensionInput.addEventListener("change", (e) => {
				if (e.target.checked) {
					rpmInput.disabled = false;
					this.#deactivateTooltip(rpmInput.parentNode);
				} else {
					rpmInput.disabled = true;
					rpmInput.checked = false;
					this.#activateTooltip(rpmInput.parentNode, rmpInputTooltip);
				}
			});
		}
	}

	#hideDateRangeInputs() {
		// Hide the date range inputs
		this.#dateRangeStartInputEl.parentNode.classList.add("hidden");
		this.#dateRangeEndInputEl.parentNode.classList.add("hidden");
	}

	#activateTooltip(element, content) {
		// If the tooltip is already initialized, update the content
		const instance_ = this.#tooltipsInstances.find(
			(tooltip) => tooltip.referenceEl === element,
		);
		if (instance_) {
			instance_.setContent(content);
			instance_.activate();
			return;
		}
		// Create a new tooltip instance
		const instance = new Popper(element, {
			class: "text-sm",
			placement: "bottom",
			trigger: "hover",
		});
		instance.setContent(content);
		this.#tooltipsInstances.push(instance);
	}

	#deactivateTooltip(element) {
		const instance = this.#tooltipsInstances.find(
			(tooltip) => tooltip.referenceEl === element,
		);
		if (instance) {
			instance.deactivate();
		}
	}

	#parseErrors(data) {
		if (data.errors !== undefined) {
			data.errors.forEach((error, index) => {
				const input = this.element.querySelector(`input[name=${error.field}]`);
				if (input !== null) {
					input.parentNode.querySelector("div.invalid-feedback").innerHTML =
						error.message;
					input.ariaInvalid = true;
				}
				// Scroll to first error input
				if (index === 0) {
					input.scrollIntoView();
				}
			});
		}
	}

	#parseForm() {
		const formData = new FormData(this.#form);
		const data = {};

		for (const [key, value] of formData) {
			// Multiple select using TomSelect
			const multiSelect = this.#multiSelectInstances.find(
				(select) => select.input.name === key,
			);
			if (multiSelect) {
				data[key] = multiSelect.items;
				continue;
			}
			// Handle array inputs
			if (key.includes("[]")) {
				const key_ = key.replace("[]", "");
				if (data[key_] === undefined) {
					data[key_] = [];
				}
				data[key_].push(value);
				continue;
			}
			data[key] = value;
		}
		return data;
	}

	#showDateRangeInputs() {
		// Set the default date range to yesterday if not already set
		if (!this.#dateRangeStartInputEl.value) {
			this.#dateRangeStartInputEl.value = this.#yesterday.format("YYYY-MM-DD");
		}
		if (!this.#dateRangeEndInputEl.value) {
			this.#dateRangeEndInputEl.value = this.#yesterday.format("YYYY-MM-DD");
		}
		// Show the date range inputs
		this.#dateRangeStartInputEl.parentNode.classList.remove("hidden");
		this.#dateRangeEndInputEl.parentNode.classList.remove("hidden");
	}
}
